import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
//import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
//import FeatureBlock from 'common/src/components/FeatureBlock';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import SectionWrapper, { CarouseWrapper, TextWrapper } from './appSlider.style';

const AppSlider = () => {

  
  const data = useStaticQuery(graphql`
    query {
      appClassicJson {
        appSlider {
          title
          description
          features {
            id
            icon
            title
            description
          }
          carousel {
            id
            title
            image {
              publicURL
            }
          }
        }
      }
    }
  `);
  const {
    //title,
    //description,
    //features,
    carousel,
  } = data.appClassicJson.appSlider;

  const glideOptions = {
    type: 'carousel',
    gap: 0,
    autoplay: 5000,
    perView: 1,
    animationDuration: 1200,
  };

  return (
    <SectionWrapper>
      <Container>
        <CarouseWrapper>
          <GlideCarousel
            bullets={false}
            controls={false}
            numberOfBullets={3}
            options={glideOptions}
            carouselSelector="appFeatureSlider"
          >
            <Fragment>

              {carousel.map(item => (
                <GlideSlide key={`feature-side--key${item.id}`}>
                  <Image src={item.image.publicURL} alt={item.title} />
                </GlideSlide>
              ))}

            </Fragment>
          </GlideCarousel>
        </CarouseWrapper>
        <TextWrapper>
          {/*}
          <Heading content={title} />
          <Text content={description} />
          {features.map(item => (
            <FeatureBlock
              key={`app-feature--key${item.id}`}
              iconPosition="left"
              icon={<i className={item.icon}></i>}
              title={<Heading as="h3" content={item.title} />}
              description={<Text content={item.description} />}
            />
          ))}
          */}
          <Heading as="h1" content={`Patient Access Portal`} />

                <Fragment>
                  <p style={{color: '#3D636C', fontWeight: '400', fontSize: '1rem'}}>
                    Health &amp; wellness at your fingertips, anywhere, anytime.<br/>
                    <strong>User Archer Health's telemedicine app to:</strong></p>

                  <ul>
                    <li style={{
                                height: '70px', 
                                paddingLeft: '86px',
                                marginBottom: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: '500',
                                fontSize: '1.25rem',
                                color: '#3D636C',
                                background: `url('/archerhealth/feature/icon-schedule-appointments.png') no-repeat top left`}}>
                    Schedule Appointments
                    </li>
                    <li style={{
                                height: '70px', 
                                paddingLeft: '86px',
                                marginBottom: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: '500',
                                fontSize: '1.25rem',
                                color: '#3D636C',
                                background: `url('/archerhealth/feature/icon-access-records.png') no-repeat top left`}}>
                    Access Records
                    </li>
                    <li style={{
                                height: '70px', 
                                paddingLeft: '86px',
                                marginBottom: '20px',
                                paddingTop: '10px',
                                fontWeight: '500',
                                fontSize: '1.25rem',
                                color: '#3D636C',
                                background: `url('/archerhealth/feature/icon-connect.png') no-repeat top left`}}>
                    Connect with Healthcare Providers<br/>
                    <span style={{fontWeight: '400', fontSize: '1rem'}}>you see in your onsite clinics</span>
                    </li>
                    <li style={{
                                height: '70px', 
                                paddingLeft: '86px',
                                marginBottom: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: '500',
                                fontSize: '1.25rem',
                                color: '#3D636C',
                                background: `url('/archerhealth/feature/icon-7days.png') no-repeat top left`}}>
                    7 Days a week / 7:00 a.m. - 7:00 p.m.
                    </li>
                  </ul>
                </Fragment>                

        </TextWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default AppSlider;
