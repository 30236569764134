import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appclassic';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppClassic/Navbar';
import Banner from '../containers/Agency/VirtualCareBanner';
import Footer from '../containers/Agency/Footer';
import GlobalStyle, {  AppWrapper, ContentWrapper } from '../containers/AppClassic/appClassic.style';
import SEO from '../components/seo';
import VirtualCareFeatureSection from '../containers/AppClassic/VirtualCareFeatureSection';
import DashboardFeatures from '../containers/AppModern/VirtualCareDashboardFeatures';
//import AboutUs from '../containers/Interior/VirtualCareAboutUs';
import Newsletter from '../containers/Agency/Newsletter';
import AppSlider from '../containers/AppClassic/VirtualCarePAP';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';

export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Virtual Care" description="Archer Health's virtual care options allow employees to connect with your onsite health providers from anywhere. Contact us to learn more & get started!" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <VirtualCareFeatureSection />
            <AppSlider />
            <DashboardFeatures />
            {/*<AboutUs />*/}
            
            <Container>
            <div id="article-content">
            <Heading as="h2" content="How Can Virtual Care for Employees Help Your Business?"/>

<p>As an employer, you know the importance of offering quality benefits in order to attract and retain the best talent. However, offering virtual care services has advantages that go beyond reducing your employee turnover. </p>

<p>In order to reduce crowds in waiting rooms due to COVID-19, virtual health care has become a more attractive option. And since COVID-19 now complicates primary care for people, virtual appointments for your employees can help them get the health care they need so they can feel better faster, with less downtime. </p>

<p>However, COVID-19 has had the unexpected benefit of making virtual care more widely accepted and available. Since more people need to have virtual appointments now, online care options have significantly increased. That makes it not only safer for people to seek medical care, but also much more convenient.</p> 
<br/><br/><br/>
<Heading as="h2" content="Optimizing Virtual Care" />

<p>Virtual care can be an important part of an overall health plan. It makes getting medical care easier for the employee while keeping costs down for you and your staff alike. Plus, it can reduce downtime and help with productivity.</p>

<p>Onsite health clinics and virtual appointments are attractive features in today’s workplace health care plans. Contact us to find out how you can leverage the latest health care options such as virtual care, onsite clinics and onsite pharmacies to keep your workforce healthier, happier and more engaged.</p>

              
            </div>
            </Container>
            <Newsletter />
            <Footer />
          </ContentWrapper>
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
